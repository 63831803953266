<template>
  <div class="manage-branches">
      
      <div class="branches">
          <div class="search">
            <el-input v-model="search" placeholder="חפש סניף">
                <template #prefix>
                    <i class="el-input__icon el-icon-search"></i>
                </template>
            </el-input>
          </div>
          <div class="add-branche">
              <el-button @click="handle_add_new_branche" type="success" icon="el-icon-plus" circle></el-button>
          </div>
        <template v-for="branche in filter_by_search" :key="branche">
            <div class="branche">
                <Branche @modify="modify_branche($event)" @delete="delete_branche($event)" :branche_name="branche" />
                </div>
        </template>
         
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import Branche from '../../../components/Admin/Shivuk_Rosman/branches/Branche.vue'
import { computed, onMounted } from '@vue/runtime-core'
import {get_branches_from_db,add_new_branche_to_db} from '../../../Methods/rosman_shivuk_funcs'
import Swal from 'sweetalert2'

export default {
    components:{Branche},
    setup(){
        const search = ref('')
        const branches = ref([])

        const handle_add_new_branche = ()=>{
            Swal.fire({
                icon: 'question',
                title: 'שם הסניף החדש',
                input: 'text',
                confirmButtonText: 'עדכן',
                cancelButtonText: 'בטל',
                showCancelButton: true,
                denyButtonText: 'בטל עדכון',
                showDenyButton: true
            }).then(async res=>{
                if(res.isConfirmed && res.value){
                    await add_new_branche_to_db(res.value)
                    branches.value.push(res.value)
                }
            })
        }
        const filter_by_search = ref(computed(()=>{
            if(search.value=='') return branches.value
            
            return branches.value.filter(branche=>{
                if(branche.includes(search.value))return branche
            })
        }))
        
        const delete_branche=(branche_name)=>{
            const index = branches.value.findIndex(branche=>branche==branche_name)
            if(index!=-1){
                branches.value.splice(index,1)
            }
        }
        const modify_branche=(data)=>{
            const index = branches.value.findIndex(branche=>branche==data.old_branche_name)
            if(index!=-1){
                branches.value[index]=data.new_branche_name
            }
        }
        onMounted(async()=>{
            branches.value = await get_branches_from_db()
        })

        return{delete_branche,modify_branche,handle_add_new_branche,search,filter_by_search}
    }
}
</script>

<style scoped>
    .manage-branches{
        width: 100%;
        height: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
    }
    .branches{
        width: 375px;
        height: 100%;
        margin-bottom: 5px;
        text-align: center;
        overflow:hidden;
        overflow-y: auto;
    }
    .branche{
        margin-bottom: 5px;
        width: 100%;
        height: 50px;
    }
    .add-branche{
        width: 100%;
        height: 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .search{
        width: 100%;
        height: 40px;
    }
</style>